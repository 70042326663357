import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { merge } from 'lodash'
import annotationPlugin from 'chartjs-plugin-annotation';

Chart.register(...registerables);
Chart.register(annotationPlugin);

function getIsWinRateChart(chartData) {
  try {
    return chartData.config?.custom?.winrate === true
  } catch (error) {
    return false
  }
}

function getIsPercentageChart(chartData) {
  try {
    return chartData.config?.custom?.percentage === true
  } catch (error) {
    return false
  }
}

function getIsDurationChart(chartData) {
  try {
    return chartData.config?.custom?.duration === true
  } catch (error) {
    return false
  }
}

function legendClickHandler(e, legendItem, legend) {
  const index = legendItem.datasetIndex;
  const ci = legend.chart;
  if (e.native.ctrlKey) {
    let othersHidden = true;
    for (let i = 0; i < ci.data.datasets.length; i++) {
      othersHidden &= index == i || !ci.isDatasetVisible(i);
    }
    for (let i = 0; i < ci.data.datasets.length; i++) {
      ci.setDatasetVisibility(i, index == i || othersHidden);
    }
    ci.show(index);
  } else {
    if (ci.isDatasetVisible(index)) {
      ci.hide(index);
    } else {
      ci.show(index);
    }
  }
}

document.addEventListener('DOMContentLoaded', function() {
  document.querySelectorAll('[data-chart-data-script-id]').forEach(function(canvasElement) {
    const scriptId = canvasElement.dataset.chartDataScriptId
    const jsonElement = document.getElementById(scriptId)
    const chartData = JSON.parse(jsonElement.innerText)

    const isWinRateChart = getIsWinRateChart(chartData)
    const isPercentageChart = isWinRateChart || getIsPercentageChart(chartData)
    const isDurationChart = getIsDurationChart(chartData)
    const chartType = chartData.config?.custom?.type || 'line'
    const showGaps = chartData.config?.custom?.showGaps || false
    const baseline = chartData.config?.custom?.baseline || (isWinRateChart ? 50.0 : null)

    const annotations = []

    if (baseline) {
      annotations.push({
        type: 'line',
        borderColor: '#ccc',
        borderWidth: 0.5,
        borderDash: [5, 15],
        scaleID: 'y',
        value: baseline,
      });
    }

    if (chartData.config?.custom?.annotations) {
      annotations.push(...chartData.config?.custom?.annotations)
    }

    const chartOptions = merge({
      maintainAspectRatio: false,
      responsive: true,
      // elements: {
      //   line: {
      //     tension: 0,
      //   }
      // },
      scales: {
        x: { },
        y: {
          ticks: isPercentageChart ? {
            callback: function (value, _index, _values) {
              return `${value.toFixed(0).toString()}%`
            },
          } : isDurationChart ? {
            callback: function (value, _index, _values) {
              return `${(value / 60.0).toFixed(1).toString()} min`
            },
          } : {},
          // min: 0.0,
          // max: 100.0,
        },
      },
      plugins: {
        tooltip: {
          callbacks: isPercentageChart ? {
            label: (context) => {
              if (context.dataset.label) {
                return `${context.dataset.label}: ${context.parsed.y.toFixed(1)}%`
              } else {
                return `${context.parsed.y.toFixed(1)}%`
              }
            }
          } : isDurationChart ? {
            label: (context) => {
              if (context.dataset.label) {
                return `${context.dataset.label}: ${context.parsed.y.toFixed(1)} min`
              } else {
                return `${context.parsed.y.toFixed(1)} min`
              }
            }
          } : {},
        },
        legend: {
          position: 'bottom',
          onClick: legendClickHandler
        },
        annotation: {
          annotations: annotations
        },
      },
      datasets: {
        line: {
          spanGaps: !showGaps,
        },
      },
    }, chartData.config || {})

    new Chart(
      canvasElement,
      {
        type: chartType,
        data: {
          labels: chartData.labels,
          datasets: chartData.datasets,
        },
        options: chartOptions
      },
    )
  })
})
